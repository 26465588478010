.profile-pic {
  width: 200px !important;
  height: 200px !important;
  margin-left: -40px;
}

#animate {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 2s;
  animation-name: animatebottom;
  animation-duration: 2s;
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

/* .react-github-calendar__calendar {
  border: 1px solid rgb(82, 82, 82, 0.4);
  color: #525252;
  box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
} */

.certificate {
  display: flex;
}

.image-container {
  max-height: 120px;
  max-width: 120px;
  margin: 10px;
}

.logo {
  max-height: 100px;
  max-width: 100px;
}

.certicate-credentials-container {
  padding: 10px;
}

.certificate-content {
  float: right;
  margin-left: 2.5em;
}

.certificate-name {
  text-align: left;
}

.certificate-para {
  margin: 0 0 0 !important;
}