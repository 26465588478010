.vertical-timeline {
  /* background-color: red; */
  width: 900px !important;
}

.vertical-timeline-element-date {
  font-size: 200px;
  padding: 200px;
  margin: 20px;
}

.vertical-timeline-element-content-arrow {
  display: none
}

.vertical-timeline-element-icon:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity .55s ease-in-out;
  -moz-transition: opacity .55s ease-in-out;
  -webkit-transition: opacity .55s ease-in-out;
}
.vertical-timeline-element-icon {
  opacity: 1.0;
  transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -webkit-transition: opacity 1s ease-in-out;
}

.vertical-timeline-element-content {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.vertical-timeline-element-company {
  font-size: 25px;
}

.vertical-timeline-element-title {
  font-size: 13px;
  margin-top: -12px;
}

.vertical-timeline-element-location {
  font-size: 10px;
  margin-top: -12px;
}

.vertical-timeline-element-description {
  font-size: 13px;
  margin-top: -15px;
}


/* media queries */

@media only screen and (max-width: 1169px) {

  .vertical-timeline {
    /* background-color: red; */
    width: 560px !important;
  }

  .vertical-timeline-element-date {
    font-size: 200px;
    padding: 0px;
    margin: 0px;
  }
}

@media only screen and (max-width: 600px) {

  .vertical-timeline {
    /* background-color: red; */
    width: 300px !important;
  }

  .vertical-timeline-element-date {
    font-size: 200px;
    padding: 0px;
    margin: 0px;
  }
}
