.project-header {
  color: black !important;
  font-size: 1.5em !important;
}
.project-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.more-projects {
  margin-top: 40px;
}

.card-project {
  margin: 10px;
  width: 300px;
}

.card-project:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}

.project-image {
  padding: 1.5em !important;
  width: 300px;
  height: 300px;
  object-fit: contain !important;
}

.project-content {
  text-align: center;
  height: 100px;
}

.project-button {
  text-align: center !important;
  justify-content: center;
}

/* media queries */

@media only screen and (max-width: 1169px) {
  
  .project-container {
    width: 100%;
    display: grid;
  }
}

@media only screen and (max-width: 600px) {

  .project-container {
    width: 100%;
    display: grid;
  }

  .project-description {
    margin: 20px;
  }
}